






















import { defineComponent, ref, watch } from '@vue/composition-api';
import { ActionTypes } from '@/store/modules/tools/actions';
import store, { useToolState, useUserState } from '@/store';
import '@/styles/main.scss';

export default defineComponent({
  name: 'MPopupBalloon',
  components: {
    MSponsorLinkInfo: () => import('@/components/molecules/m-sponsor-link-info.vue')
  },
  setup(props, context: any) {
    const { user } = useUserState(['user']);
    const { isBalloonPopup, isBalloonDisabled } = useToolState([
      'isBalloonPopup',
      'isBalloonDisabled'
    ]);
    const isOpenPopup = ref(false);
    const isDisplayBalloon = ref(false);
    const sponsorData = ref();
    watch(isBalloonPopup, val => {
      if (val) {
        store.dispatch(`tools/${ActionTypes.showBalloonPopup}`);
        sponsorData.value = JSON.parse(`${localStorage?.getItem(`sponsorInfo`)}`);
        isDisplayBalloon.value = true;
      }
    });
    watch(isBalloonDisabled, val => {
      if (val) {
        isDisplayBalloon.value = false;
      }
    });
    if (localStorage?.getItem(`sponsorInfo`)) {
      sponsorData.value = JSON.parse(`${localStorage?.getItem(`sponsorInfo`)}`);
      isDisplayBalloon.value = true;
    }
    const handlePopup = () => {
      isDisplayBalloon.value = false;
      isOpenPopup.value = true;
    };
    const closePopup = () => {
      localStorage?.removeItem(`sponsorInfo`);
      isDisplayBalloon.value = false;
    };
    return {
      user,
      isOpenPopup,
      isDisplayBalloon,
      sponsorData,
      handlePopup,
      closePopup
    };
  }
});
